import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

class PriceModal extends Component {
  constructor(context) {
    super(context);

    this.state = {
      showModal: true, // La modal est affichée initialement
    };

    this.close = this.close.bind(this);
  }

  // Fonction pour fermer la modal
  close() {
    this.setState({ showModal: false });
    localStorage.setItem("hasSeenPriceModal", "true"); // Enregistrer dans localStorage pour ne pas la réafficher
  }

  render() {
    return (
      <Fragment>
        {/* Modal avec React-Bootstrap */}
        <Modal
          show={this.state.showModal}
          onHide={this.close}
          backdrop="static" // Empêche la modal de se fermer en cliquant à l'extérieur
          aria-labelledby="price-modal"
          aria-hidden="true"
          centered // Centrer la modal
        >
          <div className="modal-content">
            {/* Header de la modal */}
            <div className="modal-header">
              <h5 className="modal-title" id="price-modal">
                Changement de prix
              </h5>
            </div>

            {/* Contenu de la modal */}
            <div className="modal-body">
              <p>
                Nous vous informons que nos prix vont changer à partir du 1er
                mars. Assurez-vous de consulter nos nouvelles offres à partir de
                cette date.
              </p>
            </div>

            {/* Footer de la modal */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.close} // Permet de fermer la modal
              >
                Fermer
              </button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default PriceModal;
